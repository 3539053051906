import React, {useEffect, useState} from 'react'
import {graphql} from 'gatsby'
import Layout from '../components/layout'
import {Basic, Facebook, Twitter} from '../components/SEO'
import {ArticleList, CategoryHeader, track} from '@cg-squad/ui-components'
import mixpanel from '../constants/mixpanel'
import {adDisplayDelay, delayMultiplicationFactor, setStreamData} from '../utils/articleUtil'
import {subCategoryPageAds} from '../utils/adUnits'
import {isMobile} from 'react-device-detect'
import {metadata} from '../../config'
import {addTimeout} from '../utils/timeManager'
import {Helmet} from 'react-helmet'
import SeeMore from '../components/buttons/SeeMore'

const SubCategoryPage = ({path, data, pageContext, location}) => {
  const allArticles = [...data.pillarArticles.nodes];
  data.allDatoCmsArticle.nodes.forEach(article => {
    if (!data.pillarArticles.nodes.find(a => a.id === article.id)) {
      allArticles.push(article);
    }
  })

  const [displayedSubCategoryArticles, setDisplayedSubCategoryArticles] = useState(allArticles.slice(0, 10));

  const seeMore = () => {
    setDisplayedSubCategoryArticles(allArticles.slice(0, displayedSubCategoryArticles.length + 10));
  }

  const seo = {
    title: pageContext.title,
    description: `Discover all of our ${pageContext.title} articles, written by our knowledgeable team of writers.`
  }
  const url = `${metadata.url}/${pageContext.categorySlug}/${pageContext.slug}`;

    useEffect(() => {
        setStreamData({
            category: undefined,
            subCategory: pageContext.title,
            article: undefined,
            author: undefined
        })
        track(mixpanel.MIXPANEL_PAGE_VIEW.SUB_CATEGORY);
      addTimeout(subCategoryPageAds, adDisplayDelay() * delayMultiplicationFactor(isMobile), [
        {key: 'category', value: pageContext.categorySlug},
        {key: 'subcategory', value: pageContext.slug}
      ]);
    }, []);

    return (
      <Layout relativePath={path?.replace('/', '')} path={location.pathname}>
        <Basic seo={seo}/>
        <Twitter seo={seo} url={url}/>
        <Facebook seo={seo} url={url}/>
        <Helmet>
          <link rel="canonical" href={url}/>
        </Helmet>
        <div className="wrapper" data-datocms-noindex>
          <div className={"w-full lg:flex font-arial"}>
            <div className={""}>
              <CategoryHeader category={{title: pageContext.title, slug: pageContext.slug}} isSub={true}/>
              <div className="ad-container my-4 min-h-[306px] lg:min-h-[276px]" data-datocms-noindex>
                <div className="advertisement-text">Advertisement</div>
                <div id="ht_subcategory_incontent1"/>
              </div>
              <ArticleList articles={displayedSubCategoryArticles}/>
              {displayedSubCategoryArticles.length < allArticles.length > 0 && <SeeMore onSeeMoreClick={seeMore}/>}
            </div>
            {/*<PopularPosts affiliateCategory={pageContext.slug} className={"flex-1"}/>*/}
          </div>
        </div>
      </Layout>
    )
}

export const query = graphql`
    query ArticlesSubCategoryWise($slug: String) {
        allDatoCmsArticle(filter: {category: {elemMatch: {slug: {eq: $slug}}}, website: {elemMatch: {name: {eq: "ht"}}}}, sort: {publishedAt: DESC}) {
            nodes {
                ...ArticleFieldsPlugin
            }
            totalCount
        }
        pillarArticles: allDatoCmsArticle(filter: {category: {elemMatch: {slug: {eq: $slug}}}, website: {elemMatch: {name: {eq: "ht"}}}, isCategoryPinned: {eq: true}}, sort: {publishedAt: DESC}, limit: 1) {
            nodes {
                ...ArticleFieldsPlugin
            }
        }
    }
`
export default SubCategoryPage
